<!-- Version: 1.0.1 -->
<template>
  <!-- <div style="padding: 20px"> -->
  <div class="eh-participant-component">
    <b-container fluid="true">
      <b-row align-v="baseline">
        <b-col cols="12" sm="6" class="eh-flex-container">
          <fp-input
            class="eh-margin-right eh-fix-filter-input-width"
            :label="$t('base.basic.filter')"
            @change="searchDocumentList"
            v-model="filter"
          />
          <fp-select
            class="eh-fix-filter-input-width"
            :label="$t('partner.participantList.documentsTab.documentType')"
            :items="Object.values($enums.DocumentCategory)"
            valueKey="Value"
            v-model="filterType"
            @change="searchDocumentList"
          >
            <template #element="element">
              {{ $t(element.Text) }}
            </template>
          </fp-select>
        </b-col>
        <b-col cols="12" sm="6">
          <fp-document-operation-buttons
            :disableShare="true"
            :disableUpload="true"
            :disableDelete="true"
            :disableEESZT="disableEESZT"
            :disableDictateText="true"
            :EESZTuploaded="EESZTuploaded"
            @eesztSync="eesztSync"
            @selectAll="selectAll"
            @clearSelected="clearSelected"
            @downloadDocuments="downloadDocuments"
          />
        </b-col>
      </b-row>
    </b-container>
    <fp-document-table
      @onRowSelected="onOtherRowSelected"
      :items="filteredDocumentList"
      :isSelectAll.sync="isSelectAll"
      :isSelectClear.sync="isSelectClear"
      :refId="'selectableTable'"
      :fields="documentFields"
      :hasCategoryFilter="true"
    />
  </div>
</template>
<script>
import { DocumentsLogic } from "../../../Logic/Backend/documents";

export default {
  data() {
    return {
      documentTableList: [],
      filteredDocumentList: [],
      selectedDocumentList: [],
      isSelectAll: false,
      isSelectClear: false,
      EESZTuploaded: true,
      filter: null,
      filterType: null,
      documentFields: [
        {
          key: "Selected",
          label: this.$t("base.basic.select"),
        },
        {
          key: "DocumentName",
          label: this.$t("components.table.name"),
          /* sortable: true, */
        },
        {
          key: "FileUploadedAt",
          label: this.$t("components.table.uploadDate"),
          /* sortable: true, */
        },
        {
          key: "FileType",
          label: this.$t("components.table.format"),
          /* sortable: true, */
        },
        { key: "Category", label: this.$t("components.table.type") },
      ],
    };
  },
  props: {
    participant: Object,
    disableEESZT: Boolean,
  },
  watch: {
    //participant prop változás figyelés
    async participant() {
      //dokumentum lista kérés
      await this.getDocuments();
    },
  },
  methods: {
    //kijelölt dokumentumok tárolása
    onOtherRowSelected(items) {
      this.selectedDocumentsList = items;
    },
    //dokumentum lista szűrés
    searchDocumentList() {
      //megadott input, és változó kulcsok alapján szűrt lista tárolása
      this.filteredDocumentList = this.$filterList(
        this.filter,
        this.documentTableList,
        ["FileName", "DocumentName", "FileUploadedAt"]
      );
      if (this.filterType) {
        this.filteredDocumentList = this.filteredDocumentList.filter(
          (doc) => doc.Category == this.filterType
        );
      }
    },
    //összes dokumentum kiválasztása
    selectAll() {
      console.log("SELECT_ALL");
      this.isSelectAll = true;
    },
    //dokumentum kijelölések törlése
    clearSelected() {
      this.isSelectClear = true;
    },
    //kiválasztott dokumentumok letöltése
    async downloadDocuments() {
      //kérés indítása
      const downloadDocumentsResponse = await this.downloadPatientProfileDocument(
        this.selectedDocumentsList,
        this.participant.UserId
      );
      //sikeres kérés indítások?
      if (downloadDocumentsResponse && downloadDocumentsResponse.length > 0) {
        //kérések válasza lista vizsgálata
        downloadDocumentsResponse.forEach((result) => {
          //hibás volt az adott kérés?
          if (result.Code != 0) {
            this.$bvToast.toast(result.Message, {
              //TODO: downloadByName
              title: this.$t("requestResponse.document.errorDownload"),
              variant: "danger",
              solid: true,
              AutoHideDelay: 10000,
            });
          } //TODO: sikeres üzenetek?
        });
      } //TODO: kell?
      /*  else {
        this.$bvToast.toast(
          "A művelet végrehajtására legalább egy elemet ki kell választania",
          {
            title: "Hiba",
            variant: "danger",
            solid: true,
            AutoHideDelay: 10000,
          }
        );
      } */
    },
    //töbszörös dokumentum letöltés
    async downloadPatientProfileDocument(selectedItems, participantId) {
      //repsons lista létrehozása
      var responseList = [];
      //kiválasztott elemek száma alapján többször indítjuk a letöltés kérést
      for (let i = 0; i < selectedItems.length; i++) {
        //kiválasztott dokumentum egy eleme
        const element = selectedItems[i];
        //kérés indítása aktuális dokumentum id alapján
        const response = await DocumentsLogic.downloadPatientProfileDocument(
          element.DocumentId,
          participantId
        );
        //sikeres a kérés?
        if (!response.Code) {
          //kapott választ blobként tároljuk ideiglenesen
          const blob = await response.blob();
          //TODO: nem működik a letöltés: iPhone, Ipad Chrome böngészőben a letöltés (meg se nyikkan)
          //file-á alakítjuk a blobot és adunk neki filenevet
          var file = new File([blob], element.FileName);
          //letölthető urlt készítünk neki
          var fileURL = URL.createObjectURL(file);
          //létrehozunk egy linket az oldalon
          var fileLink = document.createElement("a");
          //a linknek odaadjuk az urlt
          fileLink.href = fileURL;
          //a linknek odaadjuk a file nevet
          fileLink.download = element.FileName;
          //megadjuk a link megnyitásának módját
          fileLink.target = "_blank";
          //és click eseményét meghívjuk
          fileLink.click();
          //a response ebben az esetben sikeres, amit eltárolunk a válasz listába
          responseList.push({ Code: 0, Message: "Sikeres letöltés" });
        } else {
          //ha nem volt sikeres a kérés, akkor eltároljuk a kapott kódot, hiba üzenetet az aktuális dokumentum nevével együttes szövegben
          responseList.push({
            Code: response.Code,
            Message:
              /* " Hiba a " +
            element.FileName +
            "nevű dokumentum letöltése közben: " + */
              response.Message,
          });
        }
        //végül visszadjuk a kérés válaszok eredményét tartalmazó listát
        return responseList;
      }
    },
    //dokumentum lista kérés
    async getDocuments() {
      //this.filteredDocumentList = [];
      const getDocumentsResponse = await DocumentsLogic.getPatientProfileDocuments(
        this.participant.UserId
      );
      if (!getDocumentsResponse.Code) {
        this.documentTableList = this.filteredDocumentList = getDocumentsResponse;
      } else {
        this.$bvToast.toast(getDocumentsResponse.Message, {
          //TODO: name szótárazni
          title: this.$t("requestResponse.basic.errorGetNamedList", {
            name: "dokumentum",
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    async eesztSync() {
      this.EESZTuploaded = false;
      const response = await DocumentsLogic.eesztSyncPatientProfile(
        this.participant.UserId
      );
      if (!response.Code) {
        this.$bvToast.toast(
          this.$t("requestResponse.document.successEESZTsync", {
            count: response.DocumentCount,
          }),
          {
            title: this.$t("base.basic.sync"),
            variant: "success",
            solid: true,
          }
        );
      } else {
        this.$bvToast.toast(response.Message, {
          title: this.$t("requestResponse.document.errorEESZTsync"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
      await this.getDocuments();
      this.EESZTuploaded = true;
    },
  },
  //betöltéskor egyszer lefut
  async mounted() {
    //beteg dokumentum lista betöltés
    await this.getDocuments();
  },
};
</script>
